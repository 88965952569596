import { Injectable, inject } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { type SelectOption } from '../components/select/select.component';
import { type TreeviewPickerOption } from '../components/treeview-picker/treeview-picker.component';
import { type FileType } from '../interfaces/file-types';
import { type CommunicationRole } from '../models/communication-role';
import { type EnumerationModel } from '../models/enumerationModel';
import { HelpersService } from './helpers.service';
import { HttpService } from './http.service';
import { UserService } from './user.service';

export interface EnumModel {
  id?: string;
  key: string;
  title: string;
  sortOrder: number;
}

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  private readonly http = inject(HttpService);
  private readonly helpers = inject(HelpersService);
  private readonly userService = inject(UserService);
  private cache: Record<string, any> = {};

  constructor() {
    this.userService.isAuthenticated$.subscribe(() => {
      this.cache = {};
    });
  }

  public getCommunicationRoles(): Observable<CommunicationRole[]> {
    if (this.cache.communicationRoles) {
      return of(this.cache.communicationRoles);
    }

    return this.http.getV2('/api/enumerations/user/communicationRoles').pipe(
      map((roles: CommunicationRole[]) => {
        const sortedArr = roles.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
        this.cache.communicationRoles = sortedArr;
        return sortedArr;
      }),
    );
  }

  public async getFileCategories(): Promise<EnumerationModel[]> {
    if (this.cache.fileCategories) {
      return await Promise.resolve(this.cache.fileCategories);
    }

    return await this.http.get('/api/Enumerations/company/fileCategories').then((res) => {
      const sortedArr = res.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
      this.cache.fileCategories = sortedArr;
      return sortedArr;
    });
  }

  public getDiversityStatuses(): Observable<SelectOption[]> {
    if (this.cache.diversityStatuses) {
      return of(this.cache.diversityStatuses);
    }

    return this.http.getV2('/api/enumerations/company/diversityStatuses').pipe(
      map((res: EnumModel[]) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.diversityStatuses = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getBusinessTypes(): Observable<SelectOption[]> {
    if (this.cache.businessTypes) {
      return of(this.cache.businessTypes);
    }

    return this.http.getV2('/api/enumerations/company/businessTypes').pipe(
      map((res: any) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.businessTypes = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getCompanyTypes(): Observable<SelectOption[]> {
    if (this.cache.companyTypes) {
      return of(this.cache.companyTypes);
    }

    return this.http.getV2('/api/enumerations/company/types').pipe(
      map((res: EnumModel[]) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.companyTypes = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getLegalTypes(): Observable<SelectOption[]> {
    if (this.cache.legalTypes) {
      return of(this.cache.legalTypes);
    }

    return this.http.getV2('/api/enumerations/company/legalTypes').pipe(
      map((res: EnumModel[]) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.legalTypes = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getEmployeeNumbers(): Observable<SelectOption[]> {
    if (this.cache.employeeNumbers) {
      return of(this.cache.employeeNumbers);
    }

    return this.http.getV2('/api/enumerations/company/employeeNumbers').pipe(
      map((res: EnumModel[]) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.employeeNumbers = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getCompanySectors(): Observable<TreeviewPickerOption[]> {
    if (this.cache.companySectors) {
      return of(this.cache.companySectors);
    }

    return this.http.getV2('/api/globalSearch/filters/company').pipe(
      map((res: any) => {
        const sortedArr = this.helpers.parseTreeOptions(res.subIndustries, 'children');
        this.cache.companySectors = sortedArr;
        return sortedArr;
      }),
    );
  }

  public getCompanyProductCategories(): Observable<TreeviewPickerOption[]> {
    if (this.cache.productCategories) {
      return of(this.cache.productCategories);
    }
    return this.http.getV2('/api/enumerations/company/productCategories').pipe(
      map((res: any) => {
        const sortedArr = this.helpers.parseTreeOptions(res, 'children');
        this.cache.productCategories = sortedArr;
        return sortedArr;
      }),
    );
  }

  public async getLocationTypes(): Promise<SelectOption[]> {
    if (this.cache.locationTypes) {
      return await Promise.resolve(this.cache.locationTypes);
    }

    return await this.http.get('/api/enumerations/company/locationType').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.locationTypes = sortedArr;
      return sortedArr;
    });
  }

  public async getUserRoles(): Promise<SelectOption[]> {
    if (this.cache.userRoles) {
      return await Promise.resolve(this.cache.userRoles);
    }

    return await this.http.get('/api/companies/my/roles').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.userRoles = sortedArr;
      return sortedArr;
    });
  }

  public getAnnualRevenues(): Observable<SelectOption[]> {
    if (this.cache.annualRevenues) {
      return of(this.cache.annualRevenues);
    }

    return this.http.getV2('/api/enumerations/company/annualRevenues').pipe(
      map((res: EnumModel[]) => {
        const sortedArr = res
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((model) => {
            return {
              value: model.key,
              label: model.title,
            };
          });
        this.cache.annualRevenues = sortedArr;
        return sortedArr;
      }),
    );
  }

  public async getUsaStates(): Promise<SelectOption[]> {
    if (this.cache.usaStates) {
      return await Promise.resolve(this.cache.usaStates);
    }

    return await this.http.get('/api/enumerations/states').then((res) => {
      const sortedArr = res.map((model) => {
        return {
          value: model.code,
          label: model.code,
        };
      });
      this.cache.usaStates = sortedArr;
      return sortedArr;
    });
  }

  public async getCountries(): Promise<SelectOption[]> {
    if (this.cache.countries) {
      return await Promise.resolve(this.cache.countries);
    }

    return await this.http.get('/api/enumerations/countries').then((res) => {
      const sortedArr = res.map((model) => {
        return {
          value: model.title,
          label: model.title,
        };
      });
      this.cache.countries = sortedArr;
      return sortedArr;
    });
  }

  public async getFieldTypes(): Promise<SelectOption[]> {
    if (this.cache.fieldTypes) {
      return await Promise.resolve(this.cache.fieldTypes);
    }

    return await this.http.get('/api/enumerations/company/fieldTypes').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.fieldTypes = sortedArr;
      return sortedArr;
    });
  }

  public async getFieldAccessTypes(): Promise<SelectOption[]> {
    if (this.cache.fieldAccessTypes) {
      return await Promise.resolve(this.cache.fieldAccessTypes);
    }

    return await this.http.get('/api/enumerations/company/fieldSectionAccess').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.fieldAccessTypes = sortedArr;
      return sortedArr;
    });
  }

  public async getLinearUnits(): Promise<SelectOption[]> {
    if (this.cache.linearUnits) {
      return await Promise.resolve(this.cache.linearUnits);
    }

    return await this.http.get('/api/enumerations/linearUnitsOfMeasure').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.linearUnits = sortedArr;
      return sortedArr;
    });
  }

  public async getTemperatureUnits(): Promise<SelectOption[]> {
    if (this.cache.temperatureUnits) {
      return await Promise.resolve(this.cache.temperatureUnits);
    }

    return await this.http.get('/api/enumerations/temperatureUnitsOfMeasure').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.temperatureUnits = sortedArr;
      return sortedArr;
    });
  }

  public async getProductAccessTypes(): Promise<SelectOption[]> {
    if (this.cache.productAccessTypes) {
      return await Promise.resolve(this.cache.productAccessTypes);
    }

    return await this.http.get('/api/enumerations/company/product/accessTypes').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.productAccessTypes = sortedArr;
      return sortedArr;
    });
  }

  public async getThousandSeparators(): Promise<SelectOption[]> {
    if (this.cache.thousandSeparators) {
      return await Promise.resolve(this.cache.thousandSeparators);
    }

    return await this.http.get('/api/enumerations/thousandsSeparators').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.thousandSeparators = sortedArr;
      return sortedArr;
    });
  }

  public async getDateFormats(): Promise<SelectOption[]> {
    if (this.cache.dateFormats) {
      return await Promise.resolve(this.cache.dateFormats);
    }

    return await this.http.get('/api/enumerations/dateFormats').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.dateFormats = sortedArr;
      return sortedArr;
    });
  }

  public async getTimeFormats(): Promise<SelectOption[]> {
    if (this.cache.timeFormats) {
      return await Promise.resolve(this.cache.timeFormats);
    }

    return await this.http.get('/api/enumerations/timeFormats').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.timeFormats = sortedArr;
      return sortedArr;
    });
  }

  public async getTimeZones(): Promise<SelectOption[]> {
    if (this.cache.timeZones) {
      return await Promise.resolve(this.cache.timeZones);
    }

    return await this.http.get('/api/enumerations/timeZones').then((res: EnumModel[]) => {
      const sortedArr = res
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((model) => {
          return {
            value: model.key,
            label: model.title,
          };
        });
      this.cache.timeZones = sortedArr;
      return sortedArr;
    });
  }

  public getPhoneCodes(): SelectOption[] {
    if (this.cache.countryCodes) {
      return this.cache.countryCodes;
    }

    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const regions = phoneNumberUtil.getSupportedRegions();
    const codesSet = new Set<number>(regions.map((region) => +phoneNumberUtil.getCountryCodeForRegion(region)));
    const codes = [...codesSet].sort().map((code) => {
      return {
        value: `+${code}`,
        label: `+${code}`,
      };
    });

    this.cache.countryCodes = codes;
    return codes;
  }

  public async getFileTypes(): Promise<FileType[]> {
    if (this.cache.fileTypes) {
      return await Promise.resolve(this.cache.fileTypes);
    }

    return await this.http.get('/api/enumerations/workflow/fileCategories').then((res: FileType[]) => {
      const sortedArr = res.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
      this.cache.fileTypes = sortedArr;
      return sortedArr;
    });
  }
}
